import axios from 'axios';
import { Message } from 'element-ui';
import { useUserStore } from '@/store/index';
import { getToken } from '@/assets/js/auth';
import router from '@/router';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (useUserStore().token) {
      config.headers['X-Token'] = getToken();
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 200) {
      if (res.code === 501) {
        Message({
          message: '你的登录已过期，请重新登录',
          type: 'error',
          duration: 2000,
        });
        useUserStore()
          .resetToken()
          .then(() => {
            router.replace({ path: '/login' });
          });
      } else {
        Message({
          message: res.message || '出错啦~',
          type: 'error',
          duration: 5 * 1000,
        });
      }

      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  (error) => {
    console.log('err' + error);
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
