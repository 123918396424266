<template>
  <div class="home">
    <nav-menu></nav-menu>
    <div
      class="main-banner"
      id="top">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="caption header-text">
              <h6>助力企业出海</h6>
              <div class="line-dec"></div>
              <h4><em>一站式SDK集成</em> <span>一键接入</span></h4>

              <!-- <h4><em>一站式、一键接入</em><span></span></h4> -->
              <p>为游戏海外发行提供登录、支付等服务</p>
              <!-- <p>
                Tale is the best SEO agency website template using Bootstrap
                v5.2.2 CSS for your company. It is a free download provided by
                TemplateMo. There are 3 HTML pages,
                <a href="index.html">Home</a>, <a href="about.html">About</a>,
                and <a href="faqs.html">FAQ</a>.
              </p> -->
              <!-- <div class="main-button scroll-to-section">
                <a>立即下载</a>
              </div> -->
              <!-- <span>or</span> -->
              <div class="second-button">
                <router-link to="/contactus">联系我们</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="services section"
      id="services">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-6">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-heading">
                  <h2>我们提供不同的功能</h2>
                  <div class="line-dec"></div>
                </div>
              </div>
              <div
                class="col-lg-6 col-sm-6"
                v-for="(item, index) in docLists"
                :key="index"
                @click="handelClick(item)">
                <div class="service-item">
                  <div class="icon">
                    <img
                      v-if="index === 0"
                      src="@/assets/images/services-01.jpg"
                      class="templatemo-feature" />
                    <img
                      v-else-if="index === 1"
                      src="@/assets/images/services-04.jpg"
                      class="templatemo-feature" />
                  </div>
                  <h4>{{ item.name }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <template slot="prev"
      ><i class="fas fa-chevron-left"></i
    ></template>

    <template slot="next"
      ><i class="fas fa-chevron-right"></i
    ></template> -->
    <!-- <div
      class="projects section"
      id="projects">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-heading">
              <h2>Discover Our <em>Work</em> &amp; <span>Projects</span></h2>
              <div class="line-dec"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                doers eiusmod.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <carousel
              ref="carousel"
              :center="true"
              :items="2"
              :loop="true"
              :nav="true"
              :margin="30"
              :responsive="{
                992: {
                  items: 3,
                },
                1200: {
                  items: 4,
                },
              }">
              <div class="item">
                <img
                  src="@/assets/images/projects-01.jpg"
                  alt="" />
                <div class="down-content">
                  <h4>Digital Agency HTML Templates</h4>
                  <a href="#"><i class="fa fa-link"></i></a>
                </div>
              </div>
              <div class="item">
                <img
                  src="@/assets/images/projects-02.jpg"
                  alt="" />
                <div class="down-content">
                  <h4>Admin Dashboard CSS Templates</h4>
                  <a href="#"><i class="fa fa-link"></i></a>
                </div>
              </div>
              <div class="item">
                <img
                  src="@/assets/images/projects-03.jpg"
                  alt="" />
                <div class="down-content">
                  <h4>Best Responsive Website Layouts</h4>
                  <a href="#"><i class="fa fa-link"></i></a>
                </div>
              </div>
              <div class="item">
                <img
                  src="@/assets/images/projects-04.jpg"
                  alt="" />
                <div class="down-content">
                  <h4>HTML CSS Layouts for your websites</h4>
                  <a href="#"><i class="fa fa-link"></i></a>
                </div>
              </div>
              <div class="item">
                <img
                  src="@/assets/images/projects-02.jpg"
                  alt="" />
                <div class="down-content">
                  <h4>Bootstrap 5 Themes for Free</h4>
                  <a href="#"><i class="fa fa-link"></i></a>
                </div>
              </div>
              <div class="item">
                <img
                  src="@/assets/images/projects-03.jpg"
                  alt="" />
                <div class="down-content">
                  <h4>Mobile Friendly Website Layouts</h4>
                  <a href="#"><i class="fa fa-link"></i></a>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div> -->
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/Footer.vue';
import navMenu from '@/components/NavMenu';
import { getDocsList } from '@/api/docs';

// import carousel from 'vue-owl-carousel';

export default {
  name: 'Home',
  components: {
    SiteFooter,
    navMenu,
    // carousel,
  },
  data() {
    return {
      docLists: [],
    };
  },
  methods: {
    navChange(item, path) {
      if (path === '') {
        this.scrollView(item);
      }
    },
    scrollView(item) {
      this.activeNav = item;
      const element = document.getElementById(item);
      element.scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 上边框与视窗顶部平齐。默认值
      });
    },

    handelClick(item) {
      window.open(item.url);
      // this.$router.push({
      //   path: '/docinfo',
      //   query: { id: item.id },
      // });
    },
  },
  mounted() {
    getDocsList().then((res) => {
      this.docLists = res.data;
    });
    // 百度统计
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement('script');
      hm.src = 'https://hm.baidu.com/hm.js?9f3eb5a51ca54add8d9d5903a4452cc3';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    })();
  },
};
</script>

<style scoped>
.home {
  height: 100%;
}
</style>
