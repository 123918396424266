import request from '@/assets/js/request';

/**
 * @description 用户相关api
 */

export function getUserInfo(data) {
  return request({
    url: '/v1/openuser/info',
    method: 'post',
    data,
  });
}

export function contactusAdd(data) {
  return request({
    url: '/v1/contactus/add',
    method: 'post',
    data,
  });
}