import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import summer from 'summer';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import './router/ngrogress';
import pinia from '@/store/index';
import { PiniaVuePlugin } from 'pinia';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(summer);
Vue.use(PiniaVuePlugin);

import '@/assets/css/common.css';
import 'summer/lib/summer.css';

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app');
