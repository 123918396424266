<template>
  <div class="navMenu">
    <!-- ***** Header Area Start ***** -->
    <header class="header-area header-sticky">
      <div class="row">
        <div class="col-lg-8 col-sm-9">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <router-link
              to="/"
              class="logo">
              <img
                src="@/assets/images/bricksdk_logo.png"
                alt=""
                style="width: 210px" />
            </router-link>
            <ul class="nav">
              <li
                class="scroll-to-section"
                :class="active === 'home' ? 'nav-active' : ''">
                <router-link to="/">首页</router-link>
              </li>
              <li class="scroll-to-section">
                <a
                  target="_blank"
                  href="https://openbaochuan.feishu.cn/wiki/QeSGwRuozih1olkYYrEcvMRgnDd"
                  >文档中心</a
                >
              </li>
              <li
                class="scroll-to-section"
                :class="active === '/contactus' ? 'nav-active' : ''">
                <router-link to="/contactus">联系我们</router-link>
              </li>
              <li
                class="scroll-to-section"
                :class="active.indexOf('console') !== -1 ? 'nav-active' : ''">
                <router-link to="/console/application">控制台</router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-lg-4 col-sm-3">
          <div
            class="menu-right right"
            v-if="token">
            <el-dropdown>
              <div class="social-icons">
                <i class="el-icon-user-solid"></i>
                {{ userInfo.email }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <router-link to="/forget">
                  <el-dropdown-item> 修改密码 </el-dropdown-item>
                </router-link>
                <!-- <el-dropdown-item @click.native="logout">
                    <span style="display: block">退出登录</span>
                  </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <router-link
                to="/"
                class="back"
                >回到首页</router-link
              > -->
            <span
              @click="logout"
              class="logout"
              >退出登录</span
            >
          </div>
          <div
            class="social-icons right"
            v-else>
            <ul class="flex">
              <li>
                <a @click="handleLink('/register')">注册</a>
              </li>
              <li>
                <a> | </a>
              </li>
              <li>
                <a @click="handleLink('/login')">登录</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { useUserStore } from '@/store/index';

export default {
  name: 'navMenu',
  data() {
    return {
      menu: [
        { name: '首页' },
        { name: '应用中心' },
        {
          name: '配置管理',
        },
      ],
    };
  },
  props: {
    active: {
      type: String,
      default: 'home',
    },
  },
  computed: {
    userInfo() {
      return useUserStore().userInfo;
    },
    token() {
      return useUserStore().token;
    },
  },
  methods: {
    handleLink(url) {
      this.$router.push(url);
    },
    async logout() {
      await useUserStore()
        .logout()
        .then(() => {
          this.$router.replace('/login');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.navMenu {
  height: 90px;
  .nav-active {
    background: rgba(255, 255, 255, 0.1);
  }
  .menu-right {
    height: 80px;
    line-height: 80px;
  }
  .right {
    float: right;
    margin-right: 50px;
  }
  .back,
  .logout {
    font-size: 14px;
    color: #fff;
    margin-left: 15px;
    cursor: pointer;
  }
  .social-icons {
    color: #fff;
    font-size: 14px;
    height: 80px;
    line-height: 80px;
    a {
      cursor: pointer;
    }
    .el-icon-user-solid {
      font-size: 20px;
      vertical-align: middle;
    }
    .back {
      color: #fff;
      margin-left: 10px;
    }
  }
}
</style>
