import request from '@/assets/js/request';

/**
 * @description 登录、注册相关api
 */

export function sendCode(data) {
  return request({
    url: '/v1/openuser/sendCode',
    method: 'post',
    data,
  });
}

export function sendRegister(data) {
  return request({
    url: '/v1/openuser/register',
    method: 'post',
    data,
  });
}

export function login(data) {
  return request({
    url: '/v1/openuser/login',
    method: 'post',
    data,
  });
}

export function forgetPassword(data) {
  return request({
    url: '/v1/openuser/forget',
    method: 'post',
    data,
  });
}

export function logout(data) {
  return request({
    url: '/v1/openuser/logout',
    method: 'post',
    data,
  });
}

export function changePasswd(data) {
  return request({
    url: '/v1/openuser/changePasswd',
    method: 'post',
    data,
  });
}
