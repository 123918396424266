import request from '@/assets/js/request';

/**
 * @description 文档中心api
 */

export function getDocsInfo(data) {
  return request({
    url: '/v1/docs/info',
    method: 'post',
    data,
  });
}

export function getDocsList(data) {
  return request({
    url: '/v1/docs/list',
    method: 'post',
    data,
  });
}