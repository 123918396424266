import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import router from './index';
import { useUserStoreWithOut } from '@/store/modules/user';
import { Message } from 'element-ui';

NProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title || to.query.title) {
    const title = to.query.title || to.meta.title;
    document.title = title;
  }
  const userStore = useUserStoreWithOut();
  if (
    to.matched.some((record) => record.meta.needToken) &&
    userStore.token === undefined
  ) {
    Message.warning('请先登录~');
    next('/login');
  } else {
    NProgress.start();
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});
