import { defineStore } from 'pinia';
import { getToken, setToken, removeToken } from '@/assets/js/auth';
import { login, logout } from '@/api/login';
import { getUserInfo } from '@/api/user';
import { Message } from 'element-ui';
import { pinia } from '../index';

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      token: getToken(),
      userInfo: {},
    };
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user', //储存到浏览器中的key值，默认会以store的id作为key
        storage: localStorage, //储存方式不指定的话默认储存sessionStorage中
        paths: ['userInfo'],
      },
    ],
  },
  actions: {
    login(info) {
      return new Promise((resolve, reject) => {
        login(info)
          .then((response) => {
            if (response.code === 200) {
              const { data } = response;
              this.token = data.token;
              setToken(data.token);
              this.getUserInfo();
              Message({
                message: '登录成功~',
                type: 'success',
                duration: 3 * 1000,
              });
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((response) => {
            const { data } = response;
            if (!data) {
              reject('用户信息异常，请重新登录~');
            }
            this.userInfo = data;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        logout()
          .then((res) => {
            if (res.code === 200) {
              removeToken();
              this.resetToken();
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetToken() {
      return new Promise((resolve) => {
        this.token = getToken();
        this.userInfo = {};
        resolve();
      });
    },
  },
});

export const useUserStoreWithOut = () => {
  return useUserStore(pinia);
};
